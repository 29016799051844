import { createWebHistory, createRouter } from "vue-router";
import auth from "./Middleware/auth";
import admin from "./Middleware/admin";
import middlewarePipeline from "./Middleware/middleware-pipeline";


const routes = [

    // <------------------------- Dashbord ------------------------------------->
    {
        path: "/dashbord",
        alias: "/admin/dashbord",
        name: "Dashbord",
        meta: {
            middleware: [   
                auth,
                admin
            ],
            title:'Dashbord'
        },
        component: () => import("./Pages/Dashbord/Dashbord.vue")
    },

    {
      path: "/",
      name: "BlogWeb",
      component: () => import("./Pages/web/Index.vue"),
  },

    // <----------------------------Login Routes---------------------------------->
    {
        path: '/login',
        name: 'login',
        component: () =>
            import('./Pages/Auth/Login.vue'),
    },
 
    // <------------------------------- Categories Routes-------------------------------------->
    {
        path: "/admin/categories",
        name: "CategoriesList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Categories'
           },
        component: () => import("./Pages/Categories/List.vue"),
      },  

    //<---------------------------------Tag Routes ------------------------------------------------------>   
    {
        path: "/admin/tag",
        name: "TagList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Tags'
           },
        component: () => import("./Pages/Tag/List.vue"),
      },
    //   ------------------------------------ Users Routes -------------------------------------------------------------->
    {
        path: "/admin/users",
        name: "UsersList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Users'
           },
        component: () => import("./Pages/Users/List.vue"),
      },
      // --------------------------------Blog Routes ----------------------------------------------------------------------->
      {
        path: "/admin/blog",
        name: "BlogList",
        meta: {
          middleware: [
            auth,
           ],
           title:'Blogs'
           },
        component: () => import("./Pages/Blog/List.vue"),
      },

      {
        path: "/admin/blog/create",
        name: "BlogCreate",
        meta: {
          middleware: [
            auth,
           ],
           title:'New Blog'
           },
        component: () => import("./Pages/Blog/Create.vue"),
      },

      {
        path: "/admin/blog/edit/:id",
        name: "BlogEdit",
        meta: {
          middleware: [
            auth,
           ],
           title:'Update Blog'
           },
        component: () => import("./Pages/Blog/Edit.vue"),
      },   
       {
        path: "/admin/blog/show/:id",
        name: "BlogShow",
        meta: {
          middleware: [
            auth,
           ],
           title:'Show Blog'
           },
        component: () => import("./Pages/Blog/View.vue")
      },

      //---------------------Contact Us----------------------

      {
        path:'/admin/contact',
        name:'Contacts',
        meta: {
          middleware: [
            auth,
           ],
           title:'Contact Us'
           },
        component : () => import("./Pages/Contact us/Contact.vue") 
      },

      //-----------------------------------Profile

      {
        path:'/admin/profile',
        name:'Profile',
        meta: {
          middleware: [
            auth,
           ],
           title:'Profile'
           },
        component : () => import("./Pages/Profile/Profile.vue") 
      },


      // ----------------------Web design-------------------------

      {
        path: "/blogs/:id",
        name: "Blogtag",
        component: () => import("./Pages/web/Tages.vue"),
      },

      {
        path: "/categories/:id/blogs",
        name: "ShowCategory",
        component: () => import("./Pages/web/ShowCategory.vue"),
      },
      {
        path: "/:catchAll(.*)*",
        name: "NotFound",
  
        component: () => import("./Pages/Auth/NotFound.vue")
      },
      {
      path:"/contact",
      name:"Contact Us",
      component:() => import("./Pages/web/Contact.vue")
      }
      

]
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {

    /** Navigate to next if middleware is not applied */
    if (!to.meta.middleware) {
        return next()
       
    }
  
    const middleware = to.meta.middleware;
    const context = {
      
      to,
      from,
      next,
      //   store  | You can also pass store as an argument
    }
  
    return middleware[0]({
        ...context,
        next:middlewarePipeline(context, middleware,1)
    })
  })
export default router;
