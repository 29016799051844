<template>
  <div class="relative">
    <div v-show="!authRoute" class="absolute top-0 min-h-[30%] w-full bg-[#5E72E4] "
      style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg'); background-position-y: 50%; position:fixed; ">
      <span class="imgopasity"></span>
    </div>
    <router-view v-if="authRoute" />
    <Layout v-else>
      <template #main>
        <router-view />
      </template>
    </Layout>
  </div>

  <!--  
     <div class="relative">
    <div v-show="!authRoute" class="absolute top-0 min-h-[30%] w-full bg-[#5E72E4] " style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg'); background-position-y: 50%; position:fixed; ">
      <span class="imgopasity"></span></div>
    <router-view v-if="authRoute"/>
    <Layout v-else-if="status">
      <template #main>
        <router-view/>
      </template>
    </Layout>

    <StudentLayout v-else>
      <template #main>
        <router-view/>
      </template>
    </StudentLayout>
  </div>
   -->
</template>

<script>
import Layout from './Pages/Layout/layout.vue';
export default {
  name: 'App',
  components: {
    Layout
  },
  data() {
    return {
      authRoute: true,
      name: true,
    }
  },

  watch: {
    $route: {
      handler: function (path) {
        this.authRoute = (path.name === 'login' || path.name === 'BlogWeb' || path.name === 'Blogtag' || path.name === 'ShowCategory' || path.name === 'Contact Us')
      },
    }
  },
}
</script>

<style>
@import "@vueform/multiselect/themes/default.css";

.imgopasity {
  background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
  /* background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%); */
  /* background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%); */
  /* background-image: linear-gradient(60deg, #29323c 0%, #485563 100%); */
  /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
  /* background-color: #be5ee4; */
  position: absolute;
  background-position: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  border-end-end-radius: 20px;

}
</style>
