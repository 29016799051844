const Joi = require('joi');

export const validateCategories = (data) => {
    const schema = Joi.object({
        name: Joi.string().required().max(100).label('Name'),
    });
    
    return schema.validate(data, { abortEarly: false, allowUnknown: true });
};
// <-----------------------------categories validation ----------------------------------------------->

export const validateTags = (data) => {
    const schema = Joi.object({
        name: Joi.string().required().max(100).label('Name'),
    });
    
    return schema.validate(data, { abortEarly: false, allowUnknown: true });
};

// <-----------------------------Tag validation ----------------------------------------------->

export const validateUsers = (data) => {
    const schema = Joi.object({
        name: Joi.string().trim().required().max(100).label('Name'),
        email: Joi.string().required().email({ tlds: { allow: false } }).label('Email'),
        password: Joi.string().trim().required().max(100).label('Password'),
        password_confirmation: Joi.string().trim().required().max(100).label('Password confirmation'),
    });
    
    return schema.validate(data, { abortEarly: false, allowUnknown: true });
};

export const validateUsersUpdate = (data) => {
    const schema = Joi.object({
        name: Joi.string().trim().required().max(100).label('Name'),
        email: Joi.string().required().email({ tlds: { allow: false } }).label('Email'),
    });
    
    return schema.validate(data, { abortEarly: false, allowUnknown: true });
};
// <-----------------------------Users update validation ----------------------------------------------->
// <-----------------------------Users validation ----------------------------------------------->

export const validateBlog = (data) => {
    const schema = Joi.object({
        title: Joi.string().trim().required().max(100).label('Title'),
        slug: Joi.string().trim().required().max(100).label('Slug'),
        user_id: Joi.number().required().label('User'),
        // tag_id: Joi.number().required().label('Tag'),
        category_id: Joi.number().required().label('Categories'),
        // description: Joi.string().trim().required().max(100).label('Description'),
    });
    
    return schema.validate(data, { abortEarly: false, allowUnknown: true });
};



//<----------------------------Contact Us----------------------------------->
export const validateContact = (data) => {
    const schema = Joi.object({
        name: Joi.string().trim().required().max(100).label('Name'),
        email: Joi.string().required().email({ tlds: { allow: false } }).label('Email'),
    });
    
    return schema.validate(data, { abortEarly: false, allowUnknown: true });
};

