<script setup>
import ToastListItem from '@/Components/ToastListItem.vue';
import store from "@/store";


function remove(index) {
    store.commit('remove', index,1)
    // store.remove(index, 1)
}


</script>
<template>
    <TransitionGroup
     tag="div"
      enter-from-class="translate-x-full opacity-0"
       enter-active-class="duration-500"
        leave-active-class="duration-500"
         leave-to-class="translate-x-full opacity-0"
        class="fixed top-4 right-4 z-50 space-y-4 w-fit">

        <ToastListItem 
        v-for="(item, index) in store.state.items"
         :key="item.key"
          :message="item.message"
            @remove="remove(index)" />
    </TransitionGroup>
</template>
