<script setup>
import { onMounted } from 'vue';
import store from "@/store";
import {XMarkIcon, CheckIcon, InformationCircleIcon} from '@heroicons/vue/24/solid';

const props = defineProps({
    message: String,
    duration: {
        type: Number,
        default: 2000
    },
    type: {
        type: String,
        default: 'success'
    }
});


const emit = defineEmits(['remove']);

onMounted(() => {
    setTimeout(() => emit('remove'), store.state.settings.duration);
});

let className = '';
let iconClass = '';
let closeIcon = XMarkIcon;
let icon = CheckIcon;

switch (store.state.settings.type) {
    case 'success':
        className = ['text-gray-900 bg-green-500'];
        iconClass = ['bg-green-600 text-white rounded-full p-1']
        break;

    case 'danger':
        className = ['text-gray-100 bg-red-700']
        iconClass = ['bg-red-600 text-white rounded-full p-1']
        icon = InformationCircleIcon
        break;

    case 'info':
        className = ['text-black-500 bg-cyan-300']
        iconClass = ['bg-cyan-600 text-white rounded-full p-1']
        icon = InformationCircleIcon
        break;

    case 'warning':
        className = ['text-black-500 bg-yellow-300']
        iconClass = ['bg-yellow-600 text-white rounded-full p-1']
        icon = InformationCircleIcon
        break;
    default:

        className = ['text-black-500 bg-green-300'];
        iconClass = ['bg-green-600 text-white rounded-full p-1']

}

</script>
<style scoped>
    .shadow-alert {
        border: 1px solid transparent;
        box-shadow: 0 0 15px rgb(0 0 0 / 35%);
        color: #fff;
    }
    .animated-line{
        position: relative;
    }
    .animated-line::after{
        content:'';
        height: 2px;
        background: white;
        position: absolute;
        bottom: 0;
        animation: animatedLine 3s;
    }
    @keyframes animatedLine {
        0%{
            width: 100%;  
        }
        100%{
            width: 0;
        }
    }
</style>
<template>
    <div class="animated-line rounded-lg" role="alert" :class="className">
        <div class="p-3 flex items-center gap-2.5 shadow-alert">
            <component :is="icon" :class="iconClass" class="h-7 font-semibold"/>
            <div class="text-sm font-semibold">{{ message }}</div>
            <component :is="closeIcon" class="h-5 cursor-pointer font-semibold" />
        </div>
    </div>
</template>
