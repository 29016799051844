<script setup>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import InputLabel from '@/Components/InputLabel.vue';
import InputError from '@/Components/InputError.vue';
import TextInput from '@/Components/TextInput.vue';
import { ref, defineProps, defineEmits, onMounted } from 'vue';
import Modal from '@/Components/Modal.vue';
import {
    validateTags
} from "@/Validation/validateCategories";
import store from '@/store'

import { useStore } from 'vuex';

const stor = useStore();
const form = ref({});
const formErrors = ref({});
const props = defineProps({
    isModalOpen: Boolean
})

const emit = defineEmits(['submit', 'close-modal']);

const handleSubmitForm = async () => {
    console.log('submit');
    // const errors = validateTags(form.value);
    // formErrors.value = _.keyBy(errors.error?.details, 'context.key');

    // if (!Object.keys(formErrors.value).length) {
    let payloads = {
        url: 'users/change-password',
        data: form.value
    }
    await stor.dispatch('storeRecord', payloads).
        then((response) => {
            if (response.data.message) {
                console.log(response.data.message)
                toast(response.data.message, {
                    position: 'top-right',
                    autoClose: 3000, // 5 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: 'success', // Types: 'success', 'info', 'warning', 'error', 'default'
                });
                // store.commit('add', {
                //     message: response.data.message,
                //     type: 'success',
                //     duration: 3000,
                // });
                emit('submit');
                form.value = {}
            }
        }).catch(error => {
            if (error.response) {
                toast(response.data.message, {
                    position: 'top-right',
                    autoClose: 3000, // 5 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: 'error', // Types: 'success', 'info', 'warning', 'error', 'default'
                });
            }
        })

}
// else {
//     setTimeout(() => {
//         formErrors.value = {};
//     }, 5000)
// }
// }
</script>

<template>
    <Modal :isModalOpen="isModalOpen">
        <template #title>
            Update Password
        </template>
        <form>
            <div class="mt-3 grid grid-cols-1 gap-x-6 gap-y-4">
                <div>
                    <InputLabel for="name" value="Old Password"
                        class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
                    <TextInput id="name" type="text" class=" block w-full" v-model="form.old_password" required
                        autofocus autocomplete="name" placeholder="Enter Old Password" />
                    <InputError class="mt-2" :message="formErrors?.name?.message" />
                </div>
                <div>
                    <InputLabel for="name" value="New Password"
                        class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
                    <TextInput id="name" type="text" class=" block w-full" v-model="form.new_password" required
                        autofocus autocomplete="name" placeholder="Enter New Password" />
                    <InputError class="mt-2" :message="formErrors?.name?.message" />
                </div>
                <div>
                    <InputLabel for="name" value="Password Confirmation"
                        class="block mb-1 font-medium after:content-['*'] after:ml-0.5 after:text-red-500" />
                    <TextInput id="name" type="text" class=" block w-full" v-model="form.new_password_confirmation"
                        required autofocus autocomplete="name" placeholder="Enter Confirmation Password" />
                    <InputError class="mt-2" :message="formErrors?.name?.message" />
                </div>
            </div>
        </form>

        <template #footer>
            <PrimaryButton @click="handleSubmitForm">submit</PrimaryButton>
            <SecondaryButton @click="emit('close-modal')">Cancel</SecondaryButton>
        </template>
    </Modal>
</template>
