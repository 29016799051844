<script setup>
import { HomeIcon, UserGroupIcon, CubeIcon, ClipboardIcon,Bars3BottomLeftIcon, } from '@heroicons/vue/24/outline';
import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot, } from '@headlessui/vue';
// import profile from '@/Pages/Admin/Profile/Profile.vue';
import changePassword from '@/Pages/Auth/ChangePassword.vue';
import { ref } from 'vue';
import ToastList from '@/Components/ToastList.vue';
import { ClipboardDocumentIcon, LockClosedIcon, UserIcon, ListBulletIcon, ArrowRightOnRectangleIcon, } from '@heroicons/vue/24/solid';
import router from '@/router';
// import { IdentificationIcon, LightBulbIcon } from '@vue-hero-icons/outline';

let user = localStorage.getItem('user');
if (user) {
  user = JSON.parse(user);
}

const handleNavigation = (event, name) => {
    if (name === 'logout') {
        event.preventDefault();
        localStorage.clear();
        router.push('/login');
    }
}


const isModalOpen = ref(false);
const changePasswordModal = ref(false);
let open = ref(true);
let rednerComponent = ref(true);
let updateKey = ref(0);


const navigationItems = [
  { name: 'Dashboard', href: '/dashbord', icon: HomeIcon, current: false },
  { name: 'Categories', href: '/admin/categories', icon: ListBulletIcon, current: false },
  { name: 'Tags', href: '/admin/tag', icon: ClipboardDocumentIcon, current: false },
  { name: 'Users', href: '/admin/users', icon: UserGroupIcon, current: false },
  { name: 'Blogs', href: '/admin/blog', icon: CubeIcon, current: false },
  { name: 'Contacts', href: '/admin/contact', icon: ClipboardIcon, current: false },
  { name: 'Profile', href: '/admin/profile', icon: UserIcon, current: false },
];

</script>

<template>
  <!-- <profile :isModalOpen="isModalOpen" @closeModal="isModalOpen = false" /> -->
  <!-- <changePassword :isModalOpen="changePasswordModal" @submit="changePasswordModal = false"
    @close-modal="changePasswordModal = false" /> -->
  <!-- <div>
    <div class="bg-slate-100">
      <header class="sticky top-0 z-10 flex h-[80px] flex-shrink-0 shadow-md bg-slate-50">

        <div class="items-center flex">
          <div class=" hidden xl:block lg:block md:block">
            <div class="p-3" :class="open ? 'duration-200 gap-5 px-2 justify-between flex items-center   z-10' : ''">
              <img :class="!open && 'hidden'"
                class="h-8 w-8  rounded-full object-cover hidden xl:block lg:block md:block"
                src="http://octalinfotech.com/img/octal-logo.png" alt="Your Company" />
              <h1 :class="!open && 'hidden'" class="text-slate-400 lg:text-base font-semibold sm:space-x-1">OCTAL
                INFOTECH</h1>
            </div>
          </div>
          <div class="flex items-center font-semibold gap-5 z-50">
            <div class="">
              <Bars3BottomLeftIcon @click="open = !open" class="h-7 cursor-pointer"/>
            </div>
            <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar"
              aria-controls="default-sidebar" type="button"
              class="fixedx flex items-center p-2 ms-3 mx-5 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
              <span class="sr-only">Open sidebar</span>
              <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path clip-rule="evenodd" fill-rule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
                </path>
              </svg>
            </button>
            <span class="border-b-2 border-black z-50 md:ml-14 ml-0">{{ $route.meta.title }}</span>
          </div>
        </div>
        <div class="flex flex-1 justify-end items-center px-4">
          <div class="ml-4 flex items-start">
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton
                  class="flex max-w-xs items-center justify-between rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full"
                    src="https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
                    alt="" />
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-[250px] ml-5 inline  text-left items-center origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div
                    class="flex gap-4 h-36 flex-col mt-2 flex-shrink-0 items-center justify-center px-3 shadow-sm border-b border-black/10">
                    <img class="h-14 w-14 rounded-full object-cover" src="http://octalinfotech.com/img/octal-logo.png"
                      alt="Your Company" />
                    <h1 class="text-slate-500 text-lg font-bold space-x-3">OCTAL INFOTECH</h1>
                  </div>
                  <MenuItem v-slot="{ active }">
                  <a href="javascript:void(0)"
                    :class="[active ? 'bg-gray-100' : '', 'block px-10 py-3 mt-2  text-sm text-gray-700 font-bold']"
                    @click="isModalOpen = true">
                    <UserIcon class="w-6 h-6 inline-block mx-2" />
                    Profile
                  </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                  <a href="javascript:void(0)"
                    :class="[active ? 'bg-gray-100' : '', 'block px-10 py-3  text-sm text-gray-700 font-bold']"
                    @click="changePasswordModal = true">
                    <LockClosedIcon class="w-6 h-6 inline-block mx-2" />
                    Change Password
                  </a>
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                  <a href="/login"
                    :class="[active ? 'bg-gray-100' : '', 'block px-10 py-3 text-sm text-gray-700 font-bold']"
                    @click="handleNavigation($event, 'logout')">
                    <ArrowRightOnRectangleIcon class="w-6 h-6 inline-block mx-2" />
                    Logout
                  </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </header>
    </div>
  </div> -->

  <!-- <div class="md:flex">
    <aside id="default-sidebar"
      class="fixed top-0 left-0 z-[999] w-64 h-full transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar">
      <div class="flex gap-3 h-16 flex-shrink-0 items-center bg-slate-50 px-3 z-50">
        <img class="h-8 w-8 rounded-full object-cover" src="http://octalinfotech.com/img/octal-logo.png"
          alt="Your Company" />
        <h1 class="text-black text-lg font-bold space-x-3">OCTAL INFOTECH</h1>
      </div>
      <div
        class="h-full px-3 py-4 overflow-y-auto flex flex-1 flex-col bg-gray-50 dark:bg-gray-800 shadow-xl fixed z-50 w-64 gap-5">
        <template v-for="(item, index) in navigationItems" :key="item.name">
          <router-link v-if="item.name" :to="item.href ? item.href : '/'"
            class="group flex items-center text-sm gap-3 font-medium p-2  border-b border-gray-200 hover:no-underline hover:text-slate-400 hover:bg-slate-200 focus:bg-slate-100  active:bg-slate-100"
            :class="item?.margin && 'mt-5'">
            <component :is="item.icon" class="max-h-7" aria-hidden="true" />
            <h2 :style="{ 'transition-delay': index + '00ms' }" class="font-medium whitespace-pre duration-500"
              :class="!open && 'opacity-0 translate-x-28 overflow-hidden hidden'">
              {{ item.name }}</h2>
            <h2 :class="open ? 'hidden' : 'group-hover:px-1 group-hover:py-1'"
              class="absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit">
              {{ item.name }} </h2>
          </router-link>
        </template>
      </div>
    </aside>
  </div> -->

  <!-- <div class="md:mx-64 mx-1.5 md:mr-0 min-h-screen bg-slate-100 bg-image">
    <router-view></router-view>
  </div> -->
  <!-- <div class="md:flex flex">
                <aside class="bg-white z-50 duration-500 shadow-lg h-[52rem]  xl:block lg:block md:block" :class="open ? 'w-[5rem]' : 'w-[10rem]'">
                    <div class="scroll mt-2 flex flex-col gap-4 overflow-y-scroll" style="height: calc(100% - 10%)">
                        <template v-for="(item, index) in navigationItems" :key="item.name">
                            <router-link v-if="item.name" :to="item.href ? item.href : '/'"
                                class="group flex items-center text-sm gap-3 font-medium p-2  border-b border-gray-200 hover:no-underline hover:text-slate-400 hover:bg-slate-200 focus:bg-slate-100  active:bg-slate-100"
                                :class="item?.margin && 'mt-5'">
                                <component :is="item.icon" class="max-h-7" aria-hidden="true" />
                                <h2 :style="{ 'transition-delay': index + '00ms' }"
                                    class="font-medium whitespace-pre duration-500"
                                    :class="!open && 'opacity-0 translate-x-28 overflow-hidden hidden'">
                                    {{ item.name }}</h2>
                                <h2 :class="open ? 'hidden' : 'group-hover:px-1 group-hover:py-1'"
                                    class="absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit">
                                    {{ item.name }} </h2>
                        </router-link>
                    </template>
</div>
</aside>
<main class="duration-500 text-gray-900 w-full" v-if="rednerComponent">
  <div :key="updateKey" class="w-full">
    <ToastList />
    <slot name="main"></slot>
  </div>
</main>
</div>

<footer class="fixed w-full bottom-0 flex h-[7%] flex-shrink-0 shadow-md bg-slate-50">
  <div class="flex justify-between w-full items-center flex-wrap px-10">
    <h2>All Rights Reserved © 2023 BLOG</h2>
    <h2>v1.0.0</h2>
  </div>
</footer>
</div>
</div> -->
  <header
    class="  bg-white backdrop-blur-md backdrop-saturate-200 sticky top-0 z-10 flex h-[80px] flex-shrink-0  rounded-b-2xl">
    <div class="p-3" :class="open ? 'duration-200 gap-2 flex px-2 justify-between items-center  z-10' : ''">
      <img :class="!open && 'hidden'" class="h-16 w-16   rounded-full object-cover"
        src="https://ems-dev.octalinfotech.com/img/octal_infotech_logo-removebg-preview%20(1).004daf7e.png" alt="Your Company" />
      <h1 :class="!open && 'hidden'" class="text-slate-400 lg:text-base font-semibold sm:space-x-1">OCTAL
        INFOTECH</h1>
      <Bars3BottomLeftIcon @click="open = !open" class="h-7 cursor-pointer ml-3"  />

    </div>
    
    <div class="flex items-center font-semibold">
      <span class="border-b-2 border-black">{{ $route.meta.title }}</span>
    </div>
    
    <div class="flex flex-1 justify-end items-center px-4">
      <div class="ml-4 flex items-center md:ml-6">
        <Menu as="div" class="relative ml-3">
          <div>
            <MenuButton
              class="flex max-w-xs items-center justify-between rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="sr-only">Open user menu</span>
              <img class="h-8 w-8 rounded-full"
                src="https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
                alt="" />
            </MenuButton>
          </div>
          <transition enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems
              class="absolute right-0 z-10 mt-2 w-[250px] ml-5 inline  text-left items-center origin-top-right rounded-[20px] bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div
                class="flex gap-2 h-36 flex-col mt-2 flex-shrink-0 items-center justify-center px-3 shadow-sm border-b border-black/10">
                <img class="h-16 w-16  rounded-full object-cover" src="https://ems-dev.octalinfotech.com/img/octal_infotech_logo-removebg-preview%20(1).004daf7e.png"
                  alt="Your Company" />
                <h1 class="text-slate-900 text-lg font-bold ">Octal Infotech</h1>
              </div>
              <MenuItem v-slot="{ active }">
            <router-link to="/admin/profile" >

              <a href="javascript:void(0)"
                :class="[active ? 'bg-gray-100' : '', 'block px-10 py-3 mt-2  text-sm text-gray-700 font-bold']"
                @click="isModalOpen = true">
                <UserIcon class="w-6 h-6 inline-block mx-2" />
                Profile
              </a>
            </router-link>
              </MenuItem>
              <MenuItem v-slot="{ active }">
              <a href="javascript:void(0)"
                :class="[active ? 'bg-gray-100' : '', 'block px-10 py-3  text-sm text-gray-700 font-bold']"
                @click="changePasswordModal = true">
                <LockClosedIcon class="w-6 h-6 inline-block mx-2" />
                Change Password
              </a>
              </MenuItem>

              <MenuItem v-slot="{ active }">
              <a href="/login"
                :class="[active ? 'bg-gray-100' : '', 'block px-10 py-3 text-sm text-gray-700 font-bold']"
                @click="handleNavigation($event, 'logout')">
                <ArrowRightOnRectangleIcon class="w-6 h-6 inline-block mx-2" />
                Logout
              </a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>
  </header>
  <profile :isModalOpen="isModalOpen" @closeModal="isModalOpen = false" />
  <changePassword :isModalOpen="changePasswordModal" @submit="changePasswordModal = false"
    @close-modal="changePasswordModal = false" />
  <div  class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex min-h-0 flex-1 flex-col  ">
      <!-- <router-link to="/" class="hover:no-underline">
          <div class="flex gap-3 h-16 flex-shrink-0 items-center bg-slate-50 px-3 shadow-sm">
            <img class="object-cover h-14 w-14" src="https://octalinfotech.in/wp-content/uploads/2023/12/cropped-OCTAL-1-1.png" alt="Your Company"/>
            <h1 class="text-black text-lg font-bold space-x-3">OCTAL INFOTECH</h1>
          </div>
        </router-link> -->
      <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col  ml-3 ">
        <div
        
          class="flex min-h-[80%]  flex-col my-auto shadow-2xl bg-white backdrop-blur-md backdrop-saturate-200 rounded-2xl p-2">
          <!-- <router-link to="/" class="hover:no-underline">
              <div class="flex gap-3 justify-center items-center rounded-2xl h-16 flex-shrink-0  bg-white ">
                <img class="object-cover h-14 w-14" src="https://ims-api-dev.octalinfotech.com/storage/logo_images_/1714472300.png" alt="Your Company" />
                <h1 class="text-black text-lg font-bold space-x-3">Octal Infotech</h1>
              </div>
              <hr class="text-gray-50 w-40 mx-auto " />
            </router-link> -->
          <div class="flex flex-1 flex-col overflow-y-auto">
            <nav class="flex h-full flex-col justify-between space-y-3 px-2 py-4">
              <div>
                <template v-for="(item, i) in navigationItems" :key="item.name">
                  <div v-if="item.name && item.subMenu && item.subMenu.length > 0"
                    class="flex flex-col duration-500 relative py-[6px]">
                    <div class="flex justify-between m-2 duration-1000 relative" @click="manageSubMenu(i)"
                      :class="!isAllow(item) ? 'cursor-not-allowed' : 'cursor-pointer'">
                      <div class="flex space-x-3">
                        <component :is="item.icon"
                          :class="[item.current ? '' : '', ' flex-shrink-0 h-6 w-6', item.color   ] " aria-hidden="true" />
                        <h3 class="flex justify-center items-center">{{ item.name }}</h3>
                      </div>
                      <span v-if="item.subMenu && item.subMenu.length > 0">
                        <ChevronDownIcon class="h-4 material-icons fill-slate-500" aria-hidden="true" />
                      </span>
                    </div>
                    <!-- <transition name="slide-fade">
                        <div v-if="subMenuHandle[i]" class=" h-full rounded-lg">
                          <div v-for="(subMenu, subMenuIndex) in item.subMenu" :key="subMenu.name"
                            @click="handleSelectMenu(i, subMenuIndex)">
                            <router-link v-if="subMenu" :to="subMenu.href ? subMenu.href : '/'"
                              class="keyFrame mb-2 ml-3  button flex rounded-sm p-2 hover:shadow-xl hover:scale-[1.01] transition-all hover:bg-white hover:no-underline space-x-3">
                              <component :is="subMenu.icon"
                                :class="[subMenu.current ? '!text-white' : subMenu.color, 'flex-shrink-0 h-6 w-6 ']"
                                aria-hidden="true" />
                              <h3 class="flex justify-center items-center">{{ subMenu.name }}</h3>
                            </router-link>
                          </div>
                        </div>
                      </transition> -->
                  </div>
                  <router-link v-else-if="item.name" :to="item.href ? item.href : '/'" @click="item.current = true"
                    :class="[item.current ? '' : '', 'group flex items-center p-2 py-2  text-[14px] font-medium rounded-md no-underline space-x-4']"
                    style="text-decoration: none; border-radius: 20px;">
                    <component :is="item.icon"
                      :class="[item.current ? 'text-white!' : item.color, 'flex-shrink-0 h-6 w-6  ',]"
                      aria-hidden="true" />
                    <h3 :class="[item.current ? ' ' : '']">
                      {{ item.name }} </h3>
                  </router-link>
                </template>
              </div>
              <div>
                <!-- <RouterLink to="/" class="text-decoration-none "> -->
                  <div class="flex justify-center space-x-2  w-full px-8 py-2 font-bold leading-normal text-center text-white align-middle 
              transition-all ease-in bg-gray-800 border-0 rounded-lg shadow-md select-none bg-150 bg-x-25 text-xs
               hover:shadow-xs hover:-translate-y-px">
               <a href="/login"
                :class="[active ? 'bg-gray-100' : '', 'block   text-sm text-white font-bold']"
                @click="handleNavigation($event, 'logout')">
                <!-- <ArrowRightOnRectangleIcon class="w-6 h-6 inline-block mx-2" /> -->
                Logout 
              </a>

                    <ArrowRightOnRectangleIcon class="w-5 h-5" />
                  </div>
                <!-- </RouterLink> -->
              </div>
            </nav>
          </div>
        </div>

      </div>
      <!-- <div class="flex flex-1 flex-col overflow-y-auto">
          <nav class="flex-1 space-y-1 px-2 py-4">
            <template v-for="item in navigationItems" :key="item.name">
              <router-link v-if="item.name" :to="item.href ? item.href : '/'" :class="[item.current ? 'bg-gray-900 text-white' : 'text-black  hover:bg-blue-100 hover:text-black text-decoration-line:none ', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md no-underline']" style="text-decoration: none;">

                <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-gray-400', 'mr-3 flex-shrink-0 h-6 w-6',item.color ] " aria-hidden="true"/>
                {{ item.name }}
              </router-link>
            </template>

          </nav>
        </div> -->
    </div>
  </div>
  <div class="flex flex-col md:pl-64">
    <!-- <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 shadow-sm">
        <button type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true"/>
        </button>
        <div class="flex items-center font-semibold bg-slate-50">
          <span class="pl-3">{{ $route.meta.title }}</span>
        </div>
        <div class="flex flex-1 justify-end items-center px-4 bg-slate-50" v-show="$route.meta.title">
          <div class="justify-center md:flex hidden w-full font-semibold  md:text-xl lg:text-2xl">Institute Management
            System
          </div>
          <div class="ml-4 flex items-center md:ml-6">
            Profile dropdown
            <Menu as="div" class="relative ml-3">
              <div>

                <MenuButton class="flex max-w-xs items-center justify-between rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full" :src="profile" alt=""/>
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                    <a href="javascript:void(0)" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 font-bold']" @click="showmodal = true">Profile
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="javascript:void(0)" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 font-bold']" @click="showmodal2 = true">Change
                      Password
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', ' px-4 py-2  text-sm  text-gray-700 flex gap-4 items-center hover:no-underline font-bold']" @click="handleNavigation($event, 'logout')">
                      Logout</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        {{ showmodal }}
        <profile v-if="showmodal" @close-modal="onProfileModalClose" showmodal="showmodal"/>
        <ChangePassword v-if="showmodal2" @close-modal="showmodal2 = false" showmodal="showmodal2"/>
      </div> -->
    <main v-if="rednerComponent">
      <div :key="updateKey" class="mx-auto md:max-w-full px-10 sm:px-6 md:px-8 relative ">
        <slot name="main"></slot>
      </div>
    </main>
  </div>
</template>

<style>
.router-link-active {
  background-color: #f1f5f9 !important;
  color: black !important;
}
</style>
